import React from 'react';
import DefaultLayout from '../layouts/DefaultLayout';

import illustrationYeti from "../images/illustration-yeti.gif";

export default () => (
  <DefaultLayout
    pageTitle="Server error"
    pageDescription=""
    viewClass="header-short"
    headlineClass="500"
  >
    <div className="container">
      <section className="center">
        <figure>
          <img src={illustrationYeti} alt="Running yeti" />
        </figure>
        <h1 className="title">Yikes!</h1>
        <h2>It looks like there’s been a server error.</h2>
        <p>
          Please reach out to{' '}
          <a href="mailto:contact@andyet.com">contact@andyet.com</a>. We’d love
          to help!
        </p>
      </section>
    </div>
  </DefaultLayout>
);
